@import './icon-vars.scss';

// Font
$font-base-size: 16px;
$font-base-family: Inter,
Arial,
Helvetica,
sans-serif;
// .size
$heading-1: 1.125rem;
$heading-2: .875rem;
$heading-3: .8125rem;

$subtitle-1: .75rem;
$text-1: .75rem;
$text-2: .5rem;

$shape-radius: 8px;
$shape-radius-small: 4px;


// Pallete
$primary-back-color: #000;
$base-white-color: #fff;

$text-primary-color: $base-white-color;
$text-secondary-color: #C4C4C4;
$text-active-color: rgba(255, 255, 255, 0.3);
$text-active-color-2: rgba(255, 255, 255, 0.2);
$text-disabled-color: rgba(255, 255, 255, 0.14);

$primary-default-color: rgba(255, 255, 255, 0.12);
$primary-dark-color: rgba(255, 255, 255, 0.2);
$primary-light-color: rgba(255, 255, 255, 0.16);
$primary-disabled-color: rgba(255, 255, 255, 0.12);

// doesn't affect nothing
$secondary-default-color: #06CFFF;

$secondary-background-default: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
linear-gradient(90deg, #06CFFF 0%, #6F5EF8 52.19%, #AD3CFF 100%);
$secondary-background-hover: linear-gradient(90deg, #06CFFF 0%, #6F5EF8 52.19%, #AD3CFF 100%);
$secondary-background-pressed: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
linear-gradient(90deg, #06CFFF 0%, #6F5EF8 52.19%, #AD3CFF 100%);
$secondary-background-disabled: $primary-default-color;


$accent-color-primary: #1F1F1F;
$accent-color-secondary: #656464;
$accent-color-third: #FFAF75;
$accent-color-fourth: #FF7B7B;

$action-color-primary: #2F80ED;
$action-color-secondary: #368CFF;

//Custom colors
$custom-primary-back-dark-color: #141414;
$custom-secondary-back-dark-color: rgba(21, 21, 21, .7);
$custom-third-back-dark-color: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
$custom-curve-white-color: #717171;
$custom-curve-red-color: #E64939;
$custom-curve-green-color: #5CCA5E;
$custom-curve-blue-color: #4980FF;

// Custom icons palette
$primary-icons-colors: #4BD4FF,
#4DCCFF,
#4FC4FF,
#51BCFF,
#53B4FF,
#56A5FF,
#57A3FF,
#599DFF,
#538CFF,
#5D8BFF,
#5F83FF;

$secondary-icons-colors: #FF41C7,
#FF46B7,
#FF4EAC,
#FF4E97,
#FF5287,
#FF5677,
#FF5B73,
#FF6170,
#FF666E,
#FF6C6B,
#FF7168;

$third-icons-colors: #FFDF77,
#FFDC6A,
#FFC65E,
#FFAA4F,
#FF9241;


$fourth-icons-colors: #6CFF9E,
#6CFF9E,
#41FFA6,
#39FFBA,
#31FFCF;

$accent-icons-colors: #CE63FF;


//Gradients for color
$color-gradient-HSLHue-icon: conic-gradient(from 180deg at 50% 50%, #F73A33 0deg, #EE2CF2 57.8deg, #2774E7 125.95deg, #46EAEA 179.93deg, #41E879 232.39deg, #FBCF35 303.43deg, #F73A33 360deg);
$color-gradient-HSLSaturation-icon: linear-gradient(90deg, #B0B0B0 0.37%, #F52B1E 100%);
$color-gradient-HSLLuminance-icon: linear-gradient(90deg, #9A9A9A 0.37%, #000000 100%);

$color-gradient-hRed: linear-gradient(270deg, #D26338 -0.05%, #CE5669 49.55%, #C54C86 100.64%);
$color-gradient-hOrange: linear-gradient(270deg, #D79339 -0.05%, #CE7843 49.55%, #D26337 100.64%);
$color-gradient-hYellow: linear-gradient(270deg, #ABBB4C -0.05%, #B6A549 50.09%, #D79339 100.64%);
$color-gradient-hGreen: linear-gradient(270deg, #6CAF5B -0.07%, #6BB675 49.79%, #A3BD4D 100%);
$color-gradient-hAqua: linear-gradient(270deg, #5084DA -0.05%, #5397AD 49.98%, #6DAF5A 100.64%);
$color-gradient-hBlue: linear-gradient(270deg, #5A4CD0 -0.05%, #556CCC 49.98%, #5084D9 100.64%);
$color-gradient-hPurple: linear-gradient(270deg, #A15BB5 -0.05%, #6F4BCD 49.98%, #5B4CD0 100.64%);
$color-gradient-hMagenta: linear-gradient(270deg, #D44641 -0.05%, #BA558F 49.98%, #A25BB4 100.64%);

$color-gradient-sRed: linear-gradient(270deg, #D73D33 -0.05%, #A56461 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sOrange: linear-gradient(270deg, #E27538 -0.05%, #BA8A5F 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sYellow: linear-gradient(270deg, #D7B333 -0.05%, #AD9A58 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sGreen: linear-gradient(270deg, #4EDD4C -0.05%, #6FB569 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sAqua: linear-gradient(270deg, #4CDDCC -0.05%, #69B5B0 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sBlue: linear-gradient(270deg, #4C79EB -0.05%, #6A92CE 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sPurple: linear-gradient(270deg, #894CEB -0.05%, #726ACE 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-sMagenta: linear-gradient(270deg, #CB4CEB -0.05%, #B46ACE 67.26%, rgba(255, 255, 255, 0.2) 100.64%);

$color-gradient-lRed: linear-gradient(270deg, #FD9390 -0.05%, #A87E7D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lOrange: linear-gradient(270deg, #FDBE90 -0.05%, #A8877D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lYellow: linear-gradient(270deg, #FDE590 -0.05%, #A8A67D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lGreen: linear-gradient(270deg, #93FD90 -0.05%, #7EA87D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lAqua: linear-gradient(270deg, #90FDE3 -0.05%, #7DA89E 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lBlue: linear-gradient(270deg, #90BCFD -0.05%, #7D99A8 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lPurple: linear-gradient(270deg, #C090FD -0.05%, #907DA8 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$color-gradient-lMagenta: linear-gradient(270deg, #FD90DE -0.05%, #A27DA8 67.26%, rgba(255, 255, 255, 0.2) 100.64%);

$color-gradient-Hue: linear-gradient(270deg, #FF2424 0.77%, #D248E9 18.66%, #4359D0 35.84%, #488E93 53.59%, #45BC43 69.26%, #F2EB44 84.11%, #FF3131 99.78%);
$color-gradient-Temperature: linear-gradient(90deg, #0033F5 0%, #D3D3D3 49.95%, #F0BC35 100%);
$color-gradient-Tint: linear-gradient(90deg, #65B53E 0%, #E3E3E3 50%, #9F42B1 100%);
$color-gradient-Saturation: linear-gradient(270deg, #EA2121 0.11%, #D03FB0 21.87%, #4374F0 40.69%, #6DB038 62.02%, rgba(255, 255, 255, 0.2) 100%);
$color-gradient-Vibrance: linear-gradient(270deg, #EA2F89 4.7%, #D9BD2B 20.88%, #5C883A 37.57%, #415EA7 55.78%, rgba(255, 255, 255, 0.2) 100%);

//Gradients for B&W
$bw-gradient-lRed: linear-gradient(270deg, #FD9390 -0.05%, #A87E7D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-lYellow: linear-gradient(270deg, #FDE590 -0.05%, #A8A67D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-lGreen: linear-gradient(270deg, #93FD90 -0.05%, #7EA87D 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-lAqua: linear-gradient(270deg, #90FDE3 -0.05%, #7DA89E 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-lBlue: linear-gradient(270deg, #90BCFD -0.05%, #7D99A8 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-lMagenta: linear-gradient(270deg, #FD90DE -0.05%, #A27DA8 67.26%, rgba(255, 255, 255, 0.2) 100.64%);

$bw-gradient-sRed: linear-gradient(270deg, #D73D33 -0.05%, #A56461 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-sYellow: linear-gradient(270deg, #D7B333 -0.05%, #AD9A58 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-sGreen: linear-gradient(270deg, #4EDD4C -0.05%, #6FB569 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-sAqua: linear-gradient(270deg, #4CDDCC -0.05%, #69B5B0 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-sBlue: linear-gradient(270deg, #4C79EB -0.05%, #6A92CE 67.26%, rgba(255, 255, 255, 0.2) 100.64%);
$bw-gradient-sMagenta: linear-gradient(270deg, #CB4CEB -0.05%, #B46ACE 67.26%, rgba(255, 255, 255, 0.2) 100.64%);