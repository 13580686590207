@mixin typography($line-heaight: 120%, $font-weight: 500) {
    font-family: $font-base-family;
    line-height: $line-heaight;
    font-weight: $font-weight;
    // TODO: refactor colors
    // color: #fff;
}

$types: h4 h3 h2 h1;
$modular-scale: -2;

@each $type in $types {

    .#{$type} {
        font-size: $font-base-size + $modular-scale;
        @include typography();
    }

    $modular-scale: $modular-scale+2;
}

.h4 {
    @include typography(140%, 400);
}

.text-1,
.text-2,
.text-3 {
    @include typography(120%, 400);
}

.text-1 {
    font-size: 14px;
}

.text-2 {
    font-size: 13px;
}

.text-3 {
    font-size: 11px;
}

.text-4 {
    @include typography(110%, 400);
    font-size: 11px;
}

.link {
    @include typography(110%, 400);
    font-size: 11px;
    // color: #0A84FF;
}