$material-icons-font-family: "material-icons-regular" !default;
$icomoon-font-family: "lumicon" !default;
$icomoon-font-path: "fonts" !default;

$lumicon-tool_enhance: "\e900";
$lumicon-favorite_stroke: "\e901";
$lumicon-favorite_outline: "\e902";
$lumicon-reject: "\e903";
$lumicon-tool_landscape: "\e904";
$lumicon-presets: "\e905";
$lumicon-tool_sky: "\e906";
$lumicon-fv_cat_icn_small: "\e907";
$lumicon-tool_sunrays: "\e908";
$lumicon-compare: "\e909";
$lumicon-tool_structure: "\e90a";
$lumicon-tool_color: "\e90b";
$lumicon-tool_color_harmony: "\e90c";
$lumicon-arrow_up: "\e90d";
$lumicon-import: "\e90e";
$lumicon-tool_light: "\e90f";
$lumicon-my_cat_icn_small: "\e910";
$lumicon-tool_vignette: "\e911";
$lumicon-tool_details: "\e912";
$lumicon-purchased_cat_icn_small: "\e913";
$lumicon-legacy_cat_icn_small: "\e914";
$lumicon-tool_erase: "\e915";
$lumicon-tool_crop: "\e916";
$lumicon-export: "\e917";
$lumicon-tool_mood: "\e918";
$lumicon-tool_supercontrast: "\e919";
$lumicon-reset: "\e91a";
$lumicon-tool_black_white: "\e91b";
$lumicon-edits: "\e91c";
