@import "./icon-vars.scss";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/#{$icomoon-font-path}/#{$material-icons-font-family}.eot');
  /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('/#{$icomoon-font-path}/#{$material-icons-font-family}.woff2') format('woff2'),
  url('/#{$icomoon-font-path}/#{$material-icons-font-family}.woff') format('woff'),
  url('/#{$icomoon-font-path}/#{$material-icons-font-family}.ttf') format('truetype');
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('/#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
  url('/#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

[class^="lumicon-"],
[class*=" lumicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}',
  sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lumicon-tool_enhance {
  // color: #02b0e8;

  &:before {
    content: $lumicon-tool_enhance;
  }
}

.lumicon-favorite_stroke {
  // color: #fff;

  &:before {
    content: $lumicon-favorite_stroke;
  }
}

.lumicon-favorite_outline {
  // color: #fff;

  &:before {
    content: $lumicon-favorite_outline;
  }
}

.lumicon-reject {
  // color: #fff;

  &:before {
    content: $lumicon-reject;
  }
}

.lumicon-tool_landscape {
  // color: #367dfc;

  &:before {
    content: $lumicon-tool_landscape;
  }
}

.lumicon-presets {
  // color: #9e9e9e;

  &:before {
    content: $lumicon-presets;
  }
}

.lumicon-tool_sky {
  // color: #ff41c7;

  &:before {
    content: $lumicon-tool_sky;
  }
}

.lumicon-fv_cat_icn_small {
  &:before {
    content: $lumicon-fv_cat_icn_small;
  }
}

.lumicon-tool_sunrays {
  // color: #ff4eac;

  &:before {
    content: $lumicon-tool_sunrays;
  }
}

.lumicon-compare {
  // color: #fff;

  &:before {
    content: $lumicon-compare;
  }
}

.lumicon-tool_structure {
  // color: #199ff0;

  &:before {
    content: $lumicon-tool_structure;
  }
}

.lumicon-tool_color {
  // color: #2196f3;

  &:before {
    content: $lumicon-tool_color;
  }
}

.lumicon-tool_color_harmony {
  // color: #6cff9e;

  &:before {
    content: $lumicon-tool_color_harmony;
  }
}

.lumicon-arrow_up {
  // color: #fff;

  &:before {
    content: $lumicon-arrow_up;
  }
}

.lumicon-import {
  // color: #8e8e8e;

  &:before {
    content: $lumicon-import;
  }
}

.lumicon-tool_light {
  // color: #00bce5;

  &:before {
    content: $lumicon-tool_light;
  }
}

.lumicon-my_cat_icn_small {
  &:before {
    content: $lumicon-my_cat_icn_small;
  }
}

.lumicon-tool_vignette {
  // color: #3d75ff;

  &:before {
    content: $lumicon-tool_vignette;
  }
}

.lumicon-tool_details {
  // color: #2b8af7;

  &:before {
    content: $lumicon-tool_details;
  }
}

.lumicon-purchased_cat_icn_small {
  &:before {
    content: $lumicon-purchased_cat_icn_small;
  }
}

.lumicon-legacy_cat_icn_small {
  &:before {
    content: $lumicon-legacy_cat_icn_small;
  }
}

.lumicon-tool_erase {
  // color: #00bce5;

  &:before {
    content: $lumicon-tool_erase;
  }
}

.lumicon-tool_crop {
  // color: #fff;

  &:before {
    content: $lumicon-tool_crop;
  }
}

.lumicon-export {
  // color: #8e8e8e;

  &:before {
    content: $lumicon-export;
  }
}

.lumicon-tool_mood {
  // color: #fb549a;

  &:before {
    content: $lumicon-tool_mood;
  }
}

.lumicon-tool_supercontrast {
  // color: #87ff86;

  &:before {
    content: $lumicon-tool_supercontrast;
  }
}

.lumicon-reset {
  // color: #fff;

  &:before {
    content: $lumicon-reset;
  }
}

.lumicon-tool_black_white {
  // color: #2591f5;

  &:before {
    content: $lumicon-tool_black_white;
  }
}

.lumicon-edits {
  // color: #9e9e9e;

  &:before {
    content: $lumicon-edits;
  }
}