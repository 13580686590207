.canvas-wrapper {
    cursor: default;

    &[cursor='move'] {
        cursor: move !important;
    }

    &[cursor='grab'] {
        cursor: grab !important;
    }

    &[cursor='zoom-in'] {
        cursor: zoom-in !important;
    }

    &[cursor='rotate']:hover {
        cursor: none !important;

        .canvas-cursor {
            display: inline-block
        }
    }

    &[cursor='hide']:hover {
        cursor: none !important;
    }
}