@import './icon.scss';
@import './vars.scss';
@import './typography.scss';

html,
body {
    padding: 0;
    margin: 0;
    font-family: $font-base-family;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}